import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import Footer from 'src/components/footer'

const Page = ({ className, children, hero }) => {
  const [hasRendered, setHasRendered] = useState(false)
  useEffect(() => {
    setHasRendered(true)
  }, [])
  return (
    <>
      <main
        className={classNames('page', hasRendered && 'has-rendered', className)}
      >
        <div className="page-content">{children}</div>
      </main>
      <Footer />
    </>
  )
}

export default Page
