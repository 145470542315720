import React from 'react'
import classNames from 'classnames'

const RichText = ({ className, children, ...rest }) => {
  return (
    <div
      className={classNames('rich-text', className)}
      dangerouslySetInnerHTML={{ __html: children }}
      {...rest}
    />
  )
}

export default RichText
