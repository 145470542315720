import React from 'react'
import Link from 'src/components/link'
import { useStore } from 'src/state'
import Picture from 'src/components/picture'

const Footer = () => {
  const {
    footer: {
      copyrightText,
      privacyButtonText,
      termsButtonText,
      contactButtonText,
      socials,
      paymentIcons,
    },
  } = useStore()

  return (
    <footer className="footer">
      <div className="row row-end">
        <div className="col col-start">
          <p className="copyright">{copyrightText}</p>
          <a
            href="https://thedogpound.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            {privacyButtonText}
          </a>
          <a
            href="https://thedogpound.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            {termsButtonText}
          </a>
          <a
            href="mailto:supplements@thedogpound.com"
            target="_blank"
            rel="noreferrer"
          >
            {contactButtonText}
          </a>
        </div>
        <div className="col col-end">
          {socials.map(({ id, title, url }) => (
            <Link key={id} to={url} newTab>
              {title}
            </Link>
          ))}
        </div>
      </div>
      <div className="row payment-icons">
        {paymentIcons.map(({ id, icon }) => (
          <Picture
            key={id}
            defaultWidth={100}
            className="payment-icon"
            image={icon}
            contain
          />
        ))}
      </div>
    </footer>
  )
}

export default Footer
