import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import ReactSlick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const Slider = ({
  className,
  children,
  dots = false,
  autoplay = false,
  slidesToShow = 1.03,
  arrows = false,
}) => {
  const nodeRef = useRef()
  const sliderRef = useRef()
  const [index, setIndex] = useState(0)
  const [hasInteracted, setHasInteracted] = useState(false)

  useEffect(() => {
    const node = nodeRef.current
    const triggerResize = () => window.dispatchEvent(new Event('resize'))
    const resizeObserver = new ResizeObserver(triggerResize)
    resizeObserver.observe(node)
    return () => resizeObserver.unobserve(node)
  }, [])

  return (
    <div className={classNames('slider', className)} ref={nodeRef}>
      <ReactSlick
        autoplay={!hasInteracted && autoplay}
        ref={sliderRef}
        beforeChange={(pi, i) => setIndex(i)}
        onSwipe={() => setHasInteracted(true)}
        arrows={arrows}
        infinite
        speed={450}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
      >
        {children}
      </ReactSlick>
      {dots && children.length > 0 && (
        <div className="dots">
          {children.map((child, i) => (
            <div
              key={i}
              className={classNames('dot', i === index && 'active')}
              onKeyDown={() => {}}
              role="button"
              tabIndex="0"
              onClick={() => {
                sliderRef.current && sliderRef.current.slickGoTo(i)
                setHasInteracted(true)
              }}
              style={{
                width: `${30 / children.length}%`,
              }}
            >
              <div className="inner" />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Slider
