import React, { useState } from 'react'
import classNames from 'classnames'

const Video = ({
  className,
  src,
  fit,
  cover,
  contain,
  fadeInOnLoad,
  onPlay = () => {},
  ...rest
}) => {
  const fallback = 'https://www.w3schools.com/html/mov_bbb.mp4'
  const [loaded, setLoaded] = useState(false)

  return (
    <video
      className={classNames(
        'video',
        fit && 'fit',
        cover && 'cover',
        contain && 'contain',
        loaded && 'loaded',
        fadeInOnLoad && 'fade-in-on-load',
        className
      )}
      onPlay={(event) => {
        setLoaded(true)
        onPlay(event)
      }}
      {...rest}
    >
      <source src={src || fallback} type="video/mp4" />
      <track kind="captions" />
    </video>
  )
}

export default Video
