import React, { forwardRef } from 'react'
import classNames from 'classnames'
import Picture from 'src/components/picture'
import Video from 'src/components/video'
import { useStore } from 'src/state'

const Section = forwardRef(
  (
    {
      className,
      backgroundImage,
      portraitBackgroundImage,
      backgroundVideoUrl,
      children,
      ...rest
    },
    ref
  ) => {
    const { windowWidth, windowHeight } = useStore()
    const isPortrait = windowWidth < windowHeight

    return (
      <section className={classNames('section', className)} ref={ref}>
        {backgroundImage && (
          <Picture
            className="background-image"
            fitWidth
            cover
            fadeInOnLoad
            defaultWidth={2000}
            image={isPortrait ? portraitBackgroundImage : backgroundImage}
          />
        )}
        {backgroundVideoUrl && (
          <Video
            className="background-video"
            fit
            cover
            autoPlay
            muted
            playsInline
            loop
            fadeInOnLoad
            src={backgroundVideoUrl}
          />
        )}
        <div className="section-content">{children}</div>
      </section>
    )
  }
)

export default Section
